exports.components = {
  "component---src-templates-404-tsx-content-file-path-src-content-pages-404-md": () => import("./../../../src/templates/404.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/404.md" /* webpackChunkName: "component---src-templates-404-tsx-content-file-path-src-content-pages-404-md" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-api-events-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/api/events.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-api-events-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-api-index-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/api/index.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-api-index-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-api-methods-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/api/methods.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-api-methods-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-api-options-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/api/options.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-api-options-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-api-plugins-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/api/plugins.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-api-plugins-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-examples-index-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/examples/index.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-examples-index-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-examples-predefined-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/examples/predefined.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-examples-predefined-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-get-started-cdn-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/get-started/cdn.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-get-started-cdn-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-get-started-index-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/get-started/index.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-get-started-index-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-get-started-module-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/get-started/module.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-get-started-module-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-get-started-react-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/get-started/react.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-get-started-react-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-get-started-solid-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/get-started/solid.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-get-started-solid-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-get-started-svelte-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/get-started/svelte.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-get-started-svelte-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-get-started-vue-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/get-started/vue.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-get-started-vue-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-guides-breakpoints-md": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/guides/breakpoints.md" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-guides-breakpoints-md" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-guides-index-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/guides/index.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-guides-index-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-guides-previous-and-next-buttons-md": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/guides/previous-and-next-buttons.md" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-guides-previous-and-next-buttons-md" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-guides-slide-container-md": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/guides/slide-container.md" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-guides-slide-container-md" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-guides-slide-gaps-md": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/guides/slide-gaps.md" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-guides-slide-gaps-md" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-guides-slide-sizes-md": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/guides/slide-sizes.md" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-guides-slide-sizes-md" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-plugins-auto-height-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/plugins/auto-height.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-plugins-auto-height-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-plugins-auto-scroll-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/plugins/auto-scroll.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-plugins-auto-scroll-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-plugins-autoplay-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/plugins/autoplay.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-plugins-autoplay-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-plugins-class-names-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/plugins/class-names.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-plugins-class-names-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-plugins-fade-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/plugins/fade.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-plugins-fade-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-plugins-index-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/plugins/index.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-plugins-index-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-content-pages-plugins-wheel-gestures-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/plugins/wheel-gestures.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-content-pages-plugins-wheel-gestures-mdx" */),
  "component---src-templates-generator-tsx-content-file-path-src-content-pages-examples-generator-mdx": () => import("./../../../src/templates/Generator.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/examples/generator.mdx" /* webpackChunkName: "component---src-templates-generator-tsx-content-file-path-src-content-pages-examples-generator-mdx" */),
  "component---src-templates-home-tsx-content-file-path-src-content-pages-index-md": () => import("./../../../src/templates/Home.tsx?__contentFilePath=/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/content/pages/index.md" /* webpackChunkName: "component---src-templates-home-tsx-content-file-path-src-content-pages-index-md" */)
}


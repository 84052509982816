module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Embla Carousel","short_name":"Embla Carousel","description":"A lightweight carousel library with fluid motion and great swipe precision","start_url":"/","background_color":"#8ab4f8","theme_color":"#8ab4f8","display":"standalone","lang":"en","theme_color_in_head":false,"legacy":false,"icon":"src/assets/images/favicon.svg","icons":[{"src":"favicon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicon-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"438bb8af91f59256d3cb36c30e91b51e"},
    },{
      plugin: require('../../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor","elements":["h2","h3","h4","h5","h6"],"icon":"<svg viewBox=\"0 0 16 16\" aria-hidden=\"true\"><path d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\" fill=\"currentColor\" /></svg>"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/davidjerleke/Sites/embla-carousel/packages/embla-carousel-docs/src/components/Layout/"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
